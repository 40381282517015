import { initializeApp } from "@firebase/app";
import {
    getAuth,
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    User
} from "@firebase/auth";
import type {
    AuthErrorIFrameResponse,
    AuthIFrameRequest,
    AuthIFrameResponse,
    AuthReadyIFrameResponse,
    AuthStateChangeMessage,
    AuthUserIFrameResponse
} from "@lib/auth/iframe-types.js";
import { UNINSTALLED_FREE_USER_TAG } from "@lib/mailchimp";
import { tagUser } from "@lib/mailchimp/api.js";
import type { DirectSignIn, UserInfo } from "@lib/runtime-messaging";

const app = initializeApp({
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN
});

const auth = getAuth(app);

const PROVIDER = new GoogleAuthProvider();
PROVIDER.addScope("profile");
PROVIDER.addScope("email");
PROVIDER.setCustomParameters({
    prompt: "select_account"
});

const PARENT_FRAME = document.location.ancestorOrigins[0];

const getUserInfo = (user: User): UserInfo => ({
    displayName: user.displayName,
    email: user.email,
    uid: user.uid,
    photoURL: user.photoURL
});

async function login(
    messageId: string,
    directSignIn?: DirectSignIn
): Promise<AuthUserIFrameResponse> {
    try {
        const response = directSignIn
            ? await signInWithEmailAndPassword(
                  auth,
                  directSignIn.directEmail,
                  directSignIn.directPassword
              )
            : await signInWithPopup(auth, PROVIDER);
        const user = getUserInfo(response.user);
        const idToken = await response.user.getIdToken();

        return {
            type: "auth-user",
            messageId,
            data: {
                idToken,
                user
            }
        };
    } catch (error) {
        console.error("Login error:", error);
        throw error;
    }
}

async function logout(): Promise<void> {
    try {
        await signOut(auth);
    } catch (error) {
        console.error("Logout error:", error);
        throw error;
    }
}

const handleExtensionMessage = async (event: MessageEvent<AuthIFrameRequest>) => {
    const data = event.data;
    const messageId = data.messageId;
    try {
        let result: AuthIFrameResponse;
        const currentUser = auth?.currentUser;
        switch (data.type) {
            case "auth-login":
                result = await login(messageId, data.directSignIn);
                break;
            case "auth-logout":
                await logout();
                result = {
                    type: "auth-logout",
                    messageId,
                    data: {
                        success: true
                    }
                };
                break;
            case "auth-get-user":
                const forceRefresh = data?.forceRefresh;

                if (currentUser) {
                    const idToken = await currentUser.getIdToken(forceRefresh);
                    const user = getUserInfo(currentUser);
                    result = {
                        type: "auth-user",
                        messageId,
                        data: {
                            idToken,
                            user
                        }
                    };
                } else {
                    result = {
                        type: "auth-user",
                        data: null,
                        messageId
                    };
                }
                break;
        }
        window.parent.postMessage(result satisfies AuthIFrameResponse, PARENT_FRAME);
    } catch (error) {
        window.parent.postMessage(
            {
                type: "auth-error",
                messageId,
                data: {
                    error
                }
            } satisfies AuthErrorIFrameResponse,
            PARENT_FRAME
        );
    }
};

async function handleUninstall() {
    if (window.location.pathname === "/uninstall") {
        await logout();
        window.location.replace("https://ventrilo.ai");

        // Set user tag in mailchimp as uninstalled
        const email = auth.currentUser?.email;
        const displayName = auth.currentUser?.displayName ?? "Valued Customer";
        // TODO: Differentiate between free and plus users
        if (email) {
            await tagUser(email, displayName, UNINSTALLED_FREE_USER_TAG);
        }
    }
}

function checkValidContext() {
    if (
        window.top === window.self ||
        !PARENT_FRAME.startsWith("chrome-extension://agfcdegbapplhinejbaadfhmohblaldj")
    ) {
        throw new Error("Invalid access context");
    }
}

auth.onAuthStateChanged(async user => {
    // FIXME Weirdly this is working to send the initial auth state of the user
    //       during extension loading to render initial content menu item
    window.parent.postMessage(
        { type: "auth-ready" } satisfies AuthReadyIFrameResponse,
        PARENT_FRAME
    );

    if (user) {
        const idToken = await user.getIdToken();
        const userInfo = getUserInfo(user);
        window.parent.postMessage(
            {
                type: "auth-state-change",
                data: {
                    idToken,
                    user: userInfo
                }
            } satisfies AuthStateChangeMessage,
            PARENT_FRAME
        );
    } else {
        window.parent.postMessage(
            {
                type: "auth-state-change",
                data: null
            } satisfies AuthStateChangeMessage,
            PARENT_FRAME
        );
    }
});

async function setup() {
    await handleUninstall();

    checkValidContext();

    window.addEventListener("message", handleExtensionMessage);

    await auth.authStateReady();
    window.parent.postMessage(
        { type: "auth-ready" } satisfies AuthReadyIFrameResponse,
        PARENT_FRAME
    );
}

void setup();
